import React, { useContext } from 'react'
import { useQuery} from '@apollo/react-hooks'
import { Dimmer, Loader, Image, Segment, Grid, Transition } from 'semantic-ui-react'

import { AuthContext } from '../context/auth'
import ProjectCard from '../components/ProjectCard'
import ProjectForm from '../components/ProjectForm'
import { FETCH_PROJECTS_QUERY } from '../util/graphql'

function Home() {
    const { user } = useContext(AuthContext)
    if (!user){
        window.location.replace("/login");
    }
    const { loading, data: {getProjects: projects} } = useQuery(FETCH_PROJECTS_QUERY);
    
    const homeData = user ? (
        <Grid columns={4}>
            <Grid.Row>
            <Grid.Column>
                <h1>Projects</h1>
            </Grid.Column>
            {user && (
                <Grid.Column>
                    <ProjectForm/>
                </Grid.Column>
            )
            } 
            </Grid.Row>
            <Grid.Row>
            {loading ? (
                <Segment>
                    <Dimmer active inverted>
                    <Loader>Loading</Loader>
                    </Dimmer>
                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                </Segment>
            ) : (
                <Transition.Group>
                    {projects && projects.map(project => (
                        <Grid.Column key={project.id} style={{ marginBottom: 20 }}>
                            <ProjectCard project={project}></ProjectCard>
                        </Grid.Column>
                    ))}
                </Transition.Group>
                
            )

            }
            </Grid.Row>
        </Grid>
    ) : (<div></div>)

    return homeData;
}

export default Home;