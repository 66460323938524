import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import React from 'react'
import { Form } from 'semantic-ui-react'
import { Button, Icon, Modal, TextArea} from 'semantic-ui-react'

import { useForm } from '../util/hooks'
import { FETCH_PROJECTS_QUERY } from '../util/graphql'

function actionsReducer(state, action) {
  switch (action.type) {
    case 'close':
      return { open: false }
    case 'open':
      return { open: true, size: action.size, dimmer: action.dimmer }
    default:
      throw new Error('Unsupported action...')
  }
}

function ProjectForm() {
  const [state, dispatch] = React.useReducer(actionsReducer, {
    open: false,
    size: undefined,
    dimmer: undefined
  })
  const { open, size, dimmer } = state

  const {values, onChange, onSubmit } = useForm(createProjectCallback, {
      title: '',
      description: ''
  });

  const [createProject, { error, loading }] = useMutation(CREATE_PROJECT_MUTATION, {
      variables: values,
      update(proxy, result){
          const data = proxy.readQuery({
            query: FETCH_PROJECTS_QUERY
          })
          dispatch({ type: 'close' });
          data.getProjects = [result.data.createProject, ...data.getProjects];
          proxy.writeQuery({query: FETCH_PROJECTS_QUERY})
          values.title = ''
          values.description = ''
      }
  })
  function createProjectCallback(){
    createProject();
}


  return (
    <>
      <Button onClick={() => dispatch({ type: 'open', size: 'mini', dimmer: 'inverted' })}>
        <Icon name='plus'/>
        Add Project
      </Button>

      <Modal
        size={size}
        open={open}
        dimmer={dimmer}
        onClose={() => dispatch({ type: 'close' })}
      >
        <Modal.Header>Add a new Project</Modal.Header>
        {error &&(
            <div className="ui error message padded">
                <li key="value">{error.graphQLErrors[0].message}</li>
            </div>
        )}
        <Form onSubmit={onSubmit} className={ loading ? "loading modal-form" : 'modal-form' }>
            <Modal.Content>
                <Form.Field>
                    <Form.Input
                        placeholder="Project Title"
                        name="title"
                        onChange={onChange}
                        value={values.title}
                        error= {error ? true : false}
                    />
                    <TextArea 
                        placeholder='Project Description' 
                        name="description"
                        onChange={onChange}
                        value={values.description}
                        />
                    
                </Form.Field>
            </Modal.Content>
            <Modal.Actions className="modal-actions">
            <Button negative onClick={() => dispatch({ type: 'close' })}>
                Cancel
            </Button>
            <Button positive primary type="submit">
                Add
            </Button>
            </Modal.Actions>
        </Form>
      </Modal>
    </>
  )
}

const CREATE_PROJECT_MUTATION = gql`
    mutation createProject($title: String! $description: String!){
        createProject(title: $title description: $description){
            id title description username createdAt
        }
    }
`

export default ProjectForm