import gql from 'graphql-tag'

export const FETCH_PROJECTS_QUERY = gql`
    {
        getProjects{
        id title username description createdAt
            tasks{
                id body username createdAt
            }
        }
    }
`