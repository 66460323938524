import React from 'react'
import { Card, Icon, Label, Image } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom';

function ProjectCard({project: { title, description, createdAt, id, username }}){
    return(
        <Card>
        <Card.Content as={Link} to={`/projects/${id}`}>
            <Image
            floated='right'
            size='mini'
            src='https://i.pravatar.cc/150?u=a042581f4e29026704d'
            />
            <Card.Header>{title}</Card.Header>
            <Card.Meta>{username}</Card.Meta>
            <Card.Description>{description}</Card.Description>
        </Card.Content>
        <Card.Content extra>
            <Label>
                <Icon name='clock' />
                {moment(createdAt).fromNow(true)}
            </Label>
        </Card.Content>
        </Card>
    )
}

export default ProjectCard;